input[type='radio'] {
    height: 22px;
    width: 22px;
    margin-right: 10px;
}

input {
    box-shadow: inset -2px -2px 2px #FFFFFF, inset 2px 2px 2px rgba(192, 192, 219, 0.7);
    -webkit-appearance: none;
    border-radius: 100%;
    background: var(--background-color);
    top: 0;
    left: 0;
}

input:checked {
    background-color: var(--primary-color);
}
