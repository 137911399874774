.content {

    textarea {
        box-shadow: inset -2px -2px 2px #FFFFFF, inset 2px 2px 2px rgba(192, 192, 219, 0.7);
        border-radius: 12px;
        font-weight: 400;
        font-size: 12px;
        width: 722px;
        height: 401px;
        padding: 32px;
        resize: none;
        background-color: var(--background-color);
    }
}
