.main {
    ul {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding-top: 46px;
        list-style: none;
        margin-bottom: 51px;
    }
}

.mainOperator {
    .mainInfo {
        padding-top: 46px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin-bottom: 22px;

        li {
            display: flex;
            align-items: center;
            margin-right: 20px;
            border-radius: 50px;
            box-shadow: 2px 2px 2px 0 rgba(192, 192, 219, 0.70) inset, -2px -2px 2px 0 #FFF inset;
            padding: 18px;
            font-size: 15px;

            span {
                margin-right: 10px;
                font-size: 24px;
                font-weight: 600;
                color: var(--primary-color);
            }
        }

        li:last-child {
            margin-right: 0;
        }
    }
}
