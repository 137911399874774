footer {
  box-shadow: inset 0px -2px 2px #FFFFFF, inset 0px 2px 2px rgba(192, 192, 219, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 47px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  background-color: var(--background-color);
  z-index: 1;
}
