@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

:root {
    --background-color: #F4F4FD;
    --primary-color: #76D275;
    --secondary-color: #ED3F27;
    --text-color: #7A7A95 !important;
    --secondary-text-color: #FFFFFF !important;
}

* {
    margin: 0;
    padding: 0;
}

body {
    background: var(--background-color);
    color: var(--text-color);
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 47px;
}

input:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
}

input::-webkit-input-placeholder {
    font-family: 'Montserrat', sans-serif;
}

textarea {
    color: var(--text-color);
    font-family: 'Montserrat', sans-serif;
    border: none;
    padding: 0;
}

input {
    color: var(--text-color);
    font-family: 'Montserrat', sans-serif;
    border: none;
    padding: 0;
}

button {
    color: var(--text-color);
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
