.customButton {
    box-shadow: 6px 6px 20px 0 rgba(192, 192, 219, 0.70), -6px -6px 16px 0 #FFF;
    cursor: pointer;
    font-size: 15px;
    border-radius: 100px;
}

.buttonPrimary {
    color: var(--secondary-text-color);
    background: linear-gradient(271.12deg, #6FBE6E 0%, #8BEB8A 100%);
}

.buttonPrimary:active {
    box-shadow: 6px 6px 20px 0 rgba(192, 192, 219, 0.70), -6px -6px 16px 0 #FFF;
    background: var(--background-color);
    color: var(--primary-color);
}

.buttonPrimary:disabled {
    background: var(--text-color);
    color: var(--secondary-text-color);
}

.button{
    background: var(--background-color);
}
