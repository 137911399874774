.signIn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 50%;
    right: 0;
    bottom: 50%;
    left: 0;
    position: absolute;
    z-index: 15;

    span {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 46px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            font-size: 13px;
            height: 44px;
            min-width: 306px;
            box-shadow: inset -2px -2px 2px #FFFFFF, inset 2px 2px 2px rgba(192, 192, 219, 0.7);
            border-radius: 12px;
            padding-left: 16px;
        }

        .inputError::-webkit-input-placeholder {
            color: var(--secondary-color);
        }

        input:first-child {
            margin-bottom: 12px;
        }

        .signInButton {
            font-size: 16px;
            margin-top: 46px;
            color: var(--secondary-text-color);
            background-color: var(--primary-color);
            box-shadow: -6px -6px 16px #FFFFFF, 6px 6px 20px rgba(192, 192, 219, 0.7);
            border-radius: 50px;
            min-width: 200px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 13px 74px;
        }

        .signInButton:active {
            background-color: var(--background-color);
            color: var(--primary-color);
        }

        .signInButtonError {
            background-color: var(--secondary-color);
        }

        .signInButtonError:active {
            color: var(--secondary-color);
        }
    }
}
