header {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 74px;
    padding: 0 37px;

    .logo {
        display: flex;
        font-weight: 600;
        font-size: 16px;
        width: 33%;
        span {
            margin-left: 10px;
        }
    }
}

.help {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    width: 33%;
    ul {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            align-items: center;
            margin-right: 50px;
        }

        li:last-child {
            margin-right: 0;
        }
    }

    .link {
        padding: 0 8px;
    }
}

.info {
    display: flex;
    width: 33%;
    justify-content: flex-end;
    .user {
        font-weight: 600;
        font-size: 16px;
        display: flex;
        align-items: center;

        .link {
            margin-right: 20px;
        }
    }

    button {
        background-color: var(--primary-color);
        text-align: center;
        text-decoration: none;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
    }

    button:hover {
        background-color: var(--background-color);
        border: 2px solid var(--primary-color);
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.link {
    color: var(--text-color);
    text-decoration: none;
}

.activeLink {
    color: var(--primary-color);
}
