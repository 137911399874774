.administrator {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 74px;
    top: 50%;
    right: 0;
    bottom: 50%;
    left: 0;

    span {
        font-weight: 600;
        font-size: 16px;
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;

        .header {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            margin: 46px 0 36px 0;

            button {
                background: var(--background-color);
                width: 46px;
                height: 46px;
                filter: drop-shadow(-6px -6px 16px #FFFFFF) drop-shadow(6px 6px 20px rgba(192, 192, 219, 0.7));
                border-radius: 100%;
                text-decoration: none;
                cursor: pointer;
            }

            input {
                font-weight: 600;
                font-size: 12px;
                padding: 9px 0 9px;
                margin: 0 65px 0 65px;
            }
        }
    }

    .number {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 46px;

        span {
            font-weight: 600;
            font-size: 12px;
            margin-left: 10px;
        }

        input {
            justify-content: center;
            align-items: center;
            width: 42px;
            height: 42px;
            font-size: 13px;
            box-shadow: inset -2px -2px 2px #FFFFFF, inset 2px 2px 2px rgba(192, 192, 219, 0.7);
            border-radius: 12px;
            display: flex;
            text-align: center;
        }
    }

    .buttons {
        display: flex;
        margin-top: 46px;

        .circleButton {
            background: var(--background-color);
            width: 46px;
            height: 46px;
            filter: drop-shadow(-6px -6px 16px #FFFFFF) drop-shadow(6px 6px 20px rgba(192, 192, 219, 0.7));
            border-radius: 100%;
            cursor: pointer;
        }

        .midButton {
            margin: 0 32px;
        }

        .save:active {
            box-shadow: 6px 6px 20px 0 rgba(192, 192, 219, 0.70), -6px -6px 16px 0 #FFF;
            background: var(--background-color);
            color: var(--primary-color);
        }
    }
}

.operator {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 74px;
    top: 50%;
    right: 0;
    bottom: 50%;
    left: 0;


    .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: 722px;

        .swiperButtons {
            display: flex;
            align-items: flex-end;
            margin-top: 25px;

            .coloredButton {
                button {
                    color: var(--primary-color);
                }
            }

            .primary {
                margin: 0 65px;
            }
        }

        .swiperButtonsLast {
            // justify-content: center;
            align-items: center;
            margin-top: 25px;
            flex-direction: column;
            display: flex;

            .coloredButton {
                button {
                    margin-top: 14px;
                    color: var(--primary-color);
                }
            }
        }

        .head {
            font-weight: 600;
            margin-bottom: 11px;
        }

        .ticketHead {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 12px;
        }

        span {
            font-weight: 400;
        }

        .swiper {
            margin-top: 29px;
            height: 600px;
            display: flex;
            width: 100%;

            .swiperSlide {
                padding: 32px;
                height: 485px;
                box-shadow: 2px 2px 2px 0 rgba(192, 192, 219, 0.70) inset, -2px -2px 2px 0 #FFF inset;

                span {
                    display: flex;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 600;
                    padding-bottom: 32px;
                }

                .contentText {
                    font-weight: 400;
                    font-size: 16px;
                    white-space: pre-wrap;
                }
            }

            .ticket {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                .ticketInput {
                    display: flex;
                    flex-direction: row;

                    input:first-child {
                        margin: 0 20px 12px 0;
                    }
                }

                .ticketInputRadio {
                    display: flex;
                    margin-bottom: 42px;
                    flex-direction: column;
                    align-items: center;

                    ul {
                        flex-wrap: wrap;
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 18px;

                        li {
                            margin-right: 15px;
                        }

                        li:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .ticketTextarea {
                    span {
                        display: flex;
                        justify-content: center;
                        font-weight: 600;
                        text-align: center;
                        margin-bottom: 29px;
                    }

                    textarea {
                        width: 658px;
                        height: 150px;
                        resize: none;
                        padding: 32px;
                        border-radius: 12px;
                        background: #F4F4FD;
                        box-shadow: 2px 2px 2px 0 rgba(192, 192, 219, 0.70) inset, -2px -2px 2px 0px #FFF inset;
                    }
                }
            }
        }
    }
}

.dispatcher {
    display: flex;
    align-items: center;
    flex-direction: column;

    .ticket {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .request {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin-bottom: 46px;

        .active {
            box-shadow: 2px 2px 2px 0 rgba(192, 192, 219, 0.70) inset, -2px -2px 2px 0 #FFF inset;
        }

        li {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            border-radius: 50px;
            padding: 36px;
            font-size: 15px;
            box-shadow: 6px 6px 20px 0 rgba(192, 192, 219, 0.70), -6px -6px 16px 0px #FFF;


            span {
                color: var(--primary-color);
                font-size: 24px;
                font-weight: 600;
            }
        }

        li:last-child {
            margin-right: 0;
        }
    }

    .ticketInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 800px;
        border-radius: 50px;
        box-shadow: 2px 2px 2px 0 rgba(192, 192, 219, 0.70) inset, -2px -2px 2px 0 #FFF inset;
        padding: 32px;
        margin-bottom: 45px;

        .ticketId {
            display: flex;
            margin-bottom: 23px;
            flex-direction: column;
            align-items: center;
        }

        .ticketPerson {
            display: flex;
            margin-bottom: 32px;
            flex-direction: column;
            align-items: center;
        }

        .ticketComment {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 704px;
            height: 143px;

            h3 {
                margin-bottom: 23px;
            }

            div {
                overflow: auto;
                word-break: break-all;
            }
        }
    }
}
