.users {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 46px;
    }

    ul {
        padding: 0;
        margin-bottom: 22px;

        li {
            margin-right: 15px;
        }

        li:last-child {
            margin-right: 0;
        }
    }

    .inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 69px;

        input {
            margin-bottom: 12px;
        }

        button {
            margin-top: 58px;
        }
    }

    .administrating {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table {
            box-shadow: 2px 2px 2px 0 rgba(192, 192, 219, 0.70) inset, -2px -2px 2px 0 #FFF inset;
            border-radius: 16px;
            display: flex;
            padding: 13px 32px;
            justify-content: space-around;
            align-items: center;
            margin: 0 69px 12px 0;
            min-width: 600px;

            label {
                display: flex;
                align-items: center;
                font-size: 13px;
            }

            label:first-child {
                font-size: 13px;
                font-weight: 600;
            }

            label:before {
                content: "";
                float: left;
                margin: 0 30px;
                height: 34px;
                width: 3px;
                border-radius: 6px;
                background: #F4F4FD;
                box-shadow: 2px 2px 2px 0 rgba(192, 192, 219, 0.70) inset, -2px -2px 2px 0 #FFF inset;
            }

            label:first-child:before {
                content: none;
            }
        }

        .circleButton {
            background: var(--background-color);
            width: 46px;
            height: 46px;
            filter: drop-shadow(-6px -6px 16px #FFFFFF) drop-shadow(6px 6px 20px rgba(192, 192, 219, 0.7));
            border-radius: 100%;
            cursor: pointer;
        }
    }
}
