.categories {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 46px;
    }

    .cards {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;

        .count {
            font-size: 12px;
            font-weight: 600;
            margin: 0;
        }

        input {
            margin: 0 17.5px 0 10px;
        }

        .container {
            position: relative;
            cursor: pointer;
            -moz-user-select: none;
            -ms-user-select: none;
        }

        .container .customCheckbox {
            opacity: 0;
            margin: 0;
            width: 24px;
            height: 24px;
        }

        .checkbox {
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            background-color: var(--background-color);
            box-shadow: 2px 2px 2px 0 rgba(192, 192, 219, 0.70) inset, -2px -2px 2px 0px #FFF inset;
            border-radius: 4px;
        }

        .container .customCheckbox:checked ~ .checkbox {
            color: var(--primary-color);
        }

        .container:after {
            display: none;
        }

        .container .customCheckbox:checked ~ .checkbox:after {
            content: '\2714';
        }

        .container .checkbox:after {
            display: flex;
            justify-content: center;
            margin: 0;
            width: 24px;
            height: 24px;
        }

        .plus {
            margin: 0 6px;
            background-color: var(--background-color);
            cursor: pointer;
        }
    }

    .new {
        margin: 46px 0;

        .count {
            color: #DDD;
        }
    }
}
