li {
    display: flex;
    align-items: center;
    margin-right: 50px;

    .link {
        font-weight: 500;
        font-size: 15px;
        color: var(--text-color);
        text-decoration: none;
        box-shadow: -6px -6px 16px #FFFFFF, 6px 6px 20px rgba(192, 192, 219, 0.7);
        border-radius: 50px;
        padding: 21px 62.5px;
    }

    .link:active {
        box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.7), 2px 2px 6px rgba(192, 192, 219, 0.3), inset -2px -2px 2px #FFFFFF, inset 2px 2px 2px rgba(192, 192, 219, 0.7);
    }

    .activeLink {
        color: var(--primary-color);
        box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.7), 2px 2px 6px rgba(192, 192, 219, 0.3), inset -2px -2px 2px #FFFFFF, inset 2px 2px 2px rgba(192, 192, 219, 0.7);
    }
}

li:last-child {
    margin-right: 0;
}

.link {
    color: var(--text-color);
    text-decoration: none;
}
